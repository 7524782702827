import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Publikation1 from "../components/publikation1"
import Publikation2 from "../components/publikation2"
import Publikation3 from "../components/publikation3"
import Publikation4 from "../components/publikation4"

import "./index.css"

export default function PublikationPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Publikation"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Publikation"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Publikation</h1>
        <h2 className="referenceH2">
          Publikation in der Fachzeitschrift restauro
        </h2>
        <h3 className="referenceH3">
          Zur Problematik mit Weißschleiern und verfärbten Retuschen auf
          Tafelmalereien: Die Restaurierung der Wondreber Totentanztafeln.
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Publikation1">
              <Publikation1 id="Publikation1" />
              Totentanz, Wondreb, Tafel-Nr. 16: Partielle Weißschleierbildungen
              und verfärbte Retuschen
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Publikation2">
              <Publikation2 id="Publikation2" />
              Totentanz, Wondreb, Tafel-Nr. 16: Nach der Reduzierung der
              Weißschleierbildung und Ergänzung mit ablesbarer Strichretusche
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Publikation3">
              <Publikation3 id="Publikation3" />
              Totentanz, Wondreb, Tafel-Nr. 16, Detail aus dem linken unteren
              Bereich, nach Abnahme verfärbter Retuschen.
              <br />
              (Autorin Astrid Mendes)
            </label>
            <label htmlFor="Publikation4">
              <Publikation4 id="Publikation4" />
              Totentanz, Wondreb, Tafel-Nr. 16, Detail aus dem linken unteren
              Bereich, nach der Ergänzung mit ablesbarer Strichretusche
              <br />
              (Autorin Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Der Wondreber Totentanz ist ein Beispiel hochqualitativer
              Volkskunst und stellt durch seine Technik (Grisaille-Malerei) mit
              dem volksnahen Sujet und dem ungewöhnlichen Trägermaterial
              (Holztafeln in eine Deckenvertäfelung eingebunden) eine
              Besonderheit dar. Für die Region der Oberpfalz und dem dort
              gelegenen Wondreb ist der Totentanz kulturell von großer
              Bedeutung. Deshalb war neben der Restaurierung die Lesbarkeit der
              Darstellung ein wichtiger Aspekt.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Materialien aus vorangegangenen Restaurierungsmaßnahmen bergen
              immer wieder Probleme bei heutigen Arbeiten an den Objekten. Der
              in der Fachzeitschrift <em>restauro</em> veröffentlichte Artikel
              befasst sich mit den Wechselwirkungen zwischen Klima und
              eingebrachten Materialien aus vorangegangenen
              Restaurierungsmaßnahmen und der Konzeptfindung für die
              Restaurierung einer bemalten Holztafeldecke.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Zunächst stand die Substanzsicherung, die man mit Hausenblasenleim
              vornahm, im Vordergrund. Als Nächstes entschied man bei einigen
              Tafeln, die stark veränderten und störenden Retuschen durch eine
              ablesbare Strichretusche zu ersetzen.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Durch die Strichretusche in den Fehlstellen und der Verbesserung
              der entstellenden Retuschen erzielte man eine Homogenität. Die
              Weißschleierbildungen ließen sich mit einem Bindemittelauftrag
              reduzieren.
            </p>

            <h4 className="referenceH4">Aussage</h4>
            <p className="referenceText">
              Gegenstand der Restaurierung waren die 28 Darstellungen über den
              Tod, die um 1710 entstanden sind. Sie sind index sieben Reihen zu
              je vier Tafeln angeordnet. Als Darstellungsform wählte der
              Künstler die Grisailletechnik („Grau-in-Grau-Malerei“). Die Szenen
              zeigen, dass die Auftraggeber und der ausführende Maler einen
              Totentanz schufen, der für die ländliche Bevölkerung geeignet war.
              Indem Szenen aus dem ländlichen Leben, wie beispielsweise der
              Bauer oder Töpfer beim Verrichten ihrer Tätigkeiten, abgebildet
              wurden, während derer sie der Tod ereilt, konnten die Betrachter
              sich mit den Bildern identifizieren.
            </p>

            <h4 className="referenceH4">Resumee</h4>
            <p className="referenceText">
              Durch die Ablesbarkeit der Szenen kann die Bevölkerung diese
              Geschichte wieder nachvollziehen und die Tradition der
              Todesvergegenwärtigung bleibt erhalten.
            </p>

            <h4 className="referenceH4">Autoren</h4>
            <p className="referenceText">
              Astrid Wührl, (seit 2010 Astrid Mendes da Vitoria), verantwortlich
              für die Durchführung und Leitung der Maßnahmen. Mitbeteiligt war
              Katharina Deimel, Dipl.- Restauratorin M.A. für den Fachbereich
              gefasste Holzobjekte und Gemälde.
            </p>

            <h4 className="referenceH4">Artikel</h4>
            <p className="referenceText">
              Erschienen in der Fachzeitschrift restauro in der 1. Ausgabe 2011 auf Seite 2.
            {/* <a href={PDF} className="restaurolink">Der Artikel erschien im Jan/Febr. 2011 in der Fachzeitschrift
              restauro, www.restauro.de</a> */}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
